
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import axios from 'axios';
import pivotree from './assets/pivotree-outlines.svg'
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
const base64 =  require('base-64')

function App() {

  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState();

  const handleCallApi = () => {
    if(username) {
      const baseUrl = `https://ofa1wf1isf.execute-api.ap-south-1.amazonaws.com/Staging/Natalie-AppStream-Staging-URLcreator`
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if(!username.toLowerCase().match(emailRegex)) {
        toast("Please enter a valid email address.", {
          duration: 3000,
          position: 'top-center',
          style:{
            background : "white",
            border : "2px solid red",
            color : 'red'
          }
        })
      }
      else {
        const OSType = getOS()
        setLoading(true)
        axios.get(`${baseUrl}?name=${username}`)
          .then(function (response) {
            let encodedData; 
            if(OSType === "Windows") {
              encodedData = "amazonappstream:"+base64.encode(response.data?.Message)
            } else {
              encodedData = response.data?.Message
            }
            // handle success
            if (encodedData) {
              setLoading(false)
              window.open(encodedData,"_self")
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      }
    }
    else {
      toast("Email ID required to start the session", {
        duration: 3000,
        position: 'top-center',
        style:{
          background : "white",
          border : "2px solid red",
          color : 'red'
        }
      })
    }
  }

  function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }

  return (
    <>
					<Toaster position="bottom-center" />
      <div class="container">
          <div class="row">
          <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
              <a class="navbar-brand" href="#"><img src={pivotree} alt="Pivotree" width="149px" height="57px" /></a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div class="banner">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="banner-content">
                <h4>Welcome to the secured web interface of Natalie 5</h4>
                <p>You can access following applications using this web interface</p>

                <ol type="1">
                  <li>Natalie5 Client Application (Thick client)</li>
                  <li>Natalie5 Web Portal</li>
                  <li>Microsoft SQL Server Client (SSMS)</li>
                </ol>


                <p>Login to these applications using your existing credentials as before.</p>
                <div className='d-flex'>

                
                <InputGroup className="">
                  <Form.Control 
                    type='text' 
                    placeholder='Enter Your Email ID' 
                    className="planinput" 
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={(evt) => {
                        if(evt.key === "Enter") {
                          handleCallApi()
                        }}
                    }
                    autofocus="autofocus"
                  />
                </InputGroup>
                {
                  loading ? <Spinner animation="border" /> :
                    <Button className='' variant="primary" onClick={handleCallApi}>Start a New Session</Button>
                }
                </div>
                <br />
            

                <p><b>Note:</b> Sessions are configured to expire if it meets following conditions</p>
                <ol type="1">
                  <li>Expires after running for 6 hours, you need to start a new session to continue your work.</li>
                  <li>Session is left idle for more than 60 minutes.</li>
                </ol>
                <p>Data saved to the central database is preserved. Session based temporary files will no longer be available when session expires.<br /> Hence, download your exported data to your local machine.</p>
                <p>For any queries or technical challenges please reach out to <a href="mailto:support@pivotree.com">support@pivotree.com</a></p>





              </div>
            </div>

          </div>
        </div>
      </div>
      <section id="footer">
        <p>©2022 Pivotree. Copyright, all rights reserved</p>
      </section>

    </>
  );
}

export default App;
